const forms = document.querySelectorAll('form');

const attrObserver = new MutationObserver((mutations) => {
  mutations.forEach(change => {
    if (change.type !== 'attributes' && change.attributeName !== 'class') {
      return;
    }
    checkRequired(change.target);
  });
});

const changeable_elements_label = document.querySelectorAll('label.control-label');
changeable_elements_label.forEach(element => attrObserver.observe(element, {attributes: true}));

forms.forEach(form => {
  if (
    form.classList.contains('no-optional') || form.classList.contains('search-form') ||
    form.classList.contains('filter-form') || form.classList.contains('searchcriteria-div')
  ) {
    return;
  }

  form.querySelectorAll('label.control-label:not(.required), .checkbox > label').forEach(label => {
    handleSpanPlacement(label);
  });
});

function handleSpanPlacement(element) {
  const info = document.createElement('span');
  info.classList.add('optional-helper-text');
  if (element.classList.contains('needs-confirmation')) {
    info.innerText = 'Optional und freiwillig';
  } else {
    info.innerText = 'Optional';
  }
  element.appendChild(info);
}

function checkRequired(element) {
  if (!element.classList.contains('required')) {
    let count = 0;
    for (const item of element.childNodes.values()) {
      if (item.nodeName == 'SPAN') {
        if (item.innerText.indexOf('Optional') !== -1) {
          count++;
        }
      }
    }
    if (count == 0) {
      handleSpanPlacement(element);
    }
  } else {
    for (const item of element.childNodes.values()) {
      if (item.nodeName == 'SPAN') {
        if (item.innerText.indexOf('Optional') !== -1) {
          item.remove();
        }
      }
    }
  }
}
