import 'custom-event-polyfill';
import 'promise-polyfill';

import 'whatwg-fetch';

import './vendors/jquery';
import './vendors/bootstrap';

import 'jquery.scrollto/jquery.scrollTo.js';
import 'jquery-placeholder/jquery.placeholder.js';
import '@kdose/symfony-bootstrap/js/jquery-utils/jquery.stringtodate';
import initAllUtils from '@kdose/symfony-bootstrap/js/utils';
import './utils/bootstrap-init.js';
import './utils/confirm';
import './utils/modal';
import './utils/old-browser-warning';
import './utils/optionalfield';
import './utils/popoverescape';
import './utils/choicesspace';
import './utils/dropdowntab';
import { preventMultipleSubmits } from './utils/prevent-multiple-submits';
import './header/header';

initAllUtils();
preventMultipleSubmits();
