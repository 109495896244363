let dropdown = null;

document.body.addEventListener('keyup', event => {
  if (event.code == 'Tab') {
    if (dropdown != null && event.target.closest('.dropdown.open') == null ||
        event.target.closest('.dropdown.open') != dropdown) {
      $(parent).dropdown('toggle');
      dropdown = null;
    }
  } else {
    if (event.target.closest('.dropdown.open') != null) {
      const parent = event.target.closest('.dropdown');
      dropdown = parent;
    }
  }
});
