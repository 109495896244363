function isDateSupported() {
  const input = document.createElement('input');
  const value = 'a';
  input.setAttribute('type', 'date');
  input.setAttribute('value', value);
  return (input.value !== value);
}

if (!isDateSupported()) {
  document.querySelector('.old-browser-warning').classList.add('show');
}
