/**
 * Deaktiviert alle Submit-Buttons beim Abschicken eines Formulars.
 */
export function preventMultipleSubmits() {
  const forms = document.querySelectorAll('form');

  for (const form of forms) {
    const submitButtons = form.querySelectorAll('[type="submit"]:not(.multiple-submit)');

    form.addEventListener('submit', () => {
      for (const submitButton of submitButtons) {
        submitButton.disabled = true;
      }
    });
  }
}
