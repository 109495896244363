import Swal from 'sweetalert2';

document.querySelectorAll('.modal-info').forEach(entry => {
  entry.addEventListener('click', async (evt) => {
    evt.preventDefault();

    await modal(entry);
  });
});

async function modal(entry) {
  const msg = entry.dataset.message;

  return await Swal.fire({
    title: 'Hinweis',
    text: msg,
    showCancelButton: false,
    confirmButtonText: 'Schließen',
  });
}
