// Da nicht lösbar via Event: Dieses Event wird nach dem showDropdown von choices.js gefeuert.
// Hier werden die Dropdown-Elemente wieder unsichtbar gemacht, wenn es sich um ein Leerzeichen handelt.
document.body.addEventListener('keyup', event => {
  if (event.key == ' ' || event.code == 'Space') {
    if (event.target.classList.contains('choices__input')) {
      const parent = event.target.parentElement.parentElement.parentElement;
      parent.classList.remove('is-open');
      parent.querySelector('.choices__list--dropdown').classList.remove('is-active');
    }
  }
});
